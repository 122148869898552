@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --light: #d0d1d3;
  --dark: #212529;
  --green: #4c00ff;
}

body {
  font-family: Poppins;
  background: #ffffff;;
  line-height: 26px;
  margin: 0;
}

form>input {
  background-color: black;;
}

h1 {
  color: white;
}

.App {
  height: 75vh;
  margin-top: 15vh;
}

.faleConoscoBox {
  width: 40vw;

}

@media (max-width: 768px) {
  .faleConoscoBox {
    display: none;
  }
  .formBox {
    margin-top: 0rem;
  }
}

@media (min-width: 769px) {
  .faleConoscoBox {
    display: flex;
  }
  .formBox {
    margin-top: 0;
  }

}

.formBox{
  display: flex;
  align-items: center;
}

/* form {
  margin-top: 10px;
  background: #ffffff;
} */

.formRow {
  margin: 5px 0 10px 0;
}

.formInput {
  width: 100%;
  height: 2rem;
  padding: 10px 10px;
  background-color: black;;
  box-shadow: 0 0 0 2px #4c00ff;
  border: #4c00ff;
  color: #ffffff;
  border-radius: 20px;
}


.formInput:focus {
  background-color: white;
  outline: none;
  box-shadow: 0 0 0 2px var(--green);
  color: black;
}

.formInput::placeholder {
  color: white;
  opacity: 1;
  font-size: 12px;
}

.formInput:focus::placeholder {
  color: black;
  opacity: 1;
}

.errorMessage {
  align-items: center;
  color: #f45532;
  font-size: 12px;
  padding: 1;
  text-align: center;
  padding: 0.5rem;
}

.notifyToast {
  color: #fff;
  border-radius: 6px;
  width: 200px;
  margin-left: 75px;
  background-color: black;
}

.submit-btn {
  align-items: center;
  text-align: center;
  margin-right: 0rem;
  text-decoration: none;
  background-color: black;
  color: white !important;
  padding: 15px 50px;
  border: 2px solid #4c00ff;
  border-radius: 10px;
  margin-bottom: 25px;
  height: 3.5rem;
}

.submit-btn:hover {
  background-color: #4c00ff;
  color: white;
  border: 2px solid black;
}

.submit-btn:disabled:hover {
  background-color: white;
  color: var(--green) !important;
  cursor: wait;
}



@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}

