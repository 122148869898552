/* TypeWritter.module.css */

/* Estilos padrão para a classe typewriter-text */
.typewriter-text {
    font-size: 16px; /* Tamanho de fonte padrão */
    /* Adicione aqui outros estilos que desejar */
  }
  
  /* Estilos para telas com largura máxima de 768px (como tablets e smartphones) */
  @media (max-width: 768px) {
    .typewriter-text {
      font-size: 16px; /* Tamanho de fonte reduzido para telas menores */
    }
  }
  
  /* Estilos para telas ainda menores */
  @media (max-width: 480px) {
    .typewriter-text {
      font-size: 14px; /* Tamanho de fonte ainda menor para smartphones */
    }
  }
  