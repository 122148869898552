.vanta {
  min-height: 300px;
  height: 100vh;
  width: 100vw;
  color: rgb(255, 255, 255);
  padding: 5rem 10rem;
  font-size: 24px;
  position: flex;


}

.vantaAjust {
  margin-top: 0;
}

@media (max-width: 640px) {
  .vanta {
    font-size: 18px; 
    padding: 5rem 2rem;
    position: flex;
  }
}


